import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Accordion from "../components/accordion"
import BackgroundImageWrapper from "../components/backgroundImage"
import ClientLogos from "../components/clientLogos"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Kantoor = () => {
  return (
    <Layout>
      <Seo title="Kosten-bij-een-arbeidsconflict" />
      <BackgroundImageWrapper hero="hero4" position="center" height="353px">
        <div className="absolute inset-0 bg-gray opacity-30" />
        <div className="px-4 py-150px flex justify-center flex-col text-center relative">
          <h1 className="font-bold text-white mb-10px text-4xl">
            Over het kantoor
          </h1>
        </div>
      </BackgroundImageWrapper>

      <section className="py-75px px-5 bg-secondary">
        <div className="max-w-700px mx-auto text-center">
          <h2 className="text-27px text-primary mb-15px leading-1.2">
            Praten in plaats van procederen
          </h2>
          <p className="text-lg text-primary leading-1.625">
            Eerstmediation.nl is een bijzonder Mediation & Advocatenkantoor. Een
            kantoor dat niet procedeert. Eerstmediation.nl is in 2016 opgericht
            door Rianne Hillenaar-Cöp. Zij is naast mediator ook advocaat. Na
            ruim 20 jaar procederen is Rianne tot de overtuiging gekomen dat
            mediation als methode van conflictoplossing verreweg de voorkeur
            verdient. Rianne treedt uitsluitend op als mediation-advocaat voor
            twee partijen.
          </p>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-700px mx-auto text-center">
          <h2 className="text-27px text-primary mb-15px leading-1.2">
            Familierecht en Arbeidsrecht
          </h2>
          <p className="text-lg text-primary leading-1.625">
            U kunt bij Eerstmediation.nl uitsluitend terecht voor zaken op het
            gebied van arbeidsrecht en personen- en familierecht. Met deze
            rechtsgebieden staat Rianne Hillenaar-Cöp in het
            rechtsgebiedenregister van de Nederlandse Orde van Advocaten
            geregistreerd. Deze registratie verplicht de advocaat elk
            kalenderjaar volgens de normen van de Orde van Advocaten 10
            opleidingspunten te behalen op elk geregistreerd rechtsgebied.
          </p>
        </div>
      </section>

      <section className="py-75px px-5 bg-secondary">
        <div className="max-w-500px mx-auto text-center">
          <StaticImage
            src="../assets/images/rianne-hillenaar-cop.jpeg"
            placeholder="blurred"
            class="w-200px h-200px"
          />
          <h2 className="text-27px text-primary mb-15px leading-1.2">
            Rianne Hillenaar-Cöp
          </h2>
          <p className="text-lg text-primary leading-1.625">
            Meer informatie over de professionele achtergrond van Rianne leest u
            in haar
            <Link to="/curriculum-vitae-rianne" className="cursor-pointer text-link">
              &nbsp; Curriculum Vitae &nbsp;
            </Link>
            en wat uitgebreider op haar
            <Link
              to="https://www.linkedin.com/in/riannehillenaar"
              target="_blank"
              className="cursor-pointer text-link"
            >
              &nbsp; linkedinpagina
            </Link>
            . Rianne is getrouwd en moeder van drie volwassen kinderen.
          </p>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-700px mx-auto">
          <div className="flex flex-col gap-y-2">
            <Accordion
              title="Algemene voorwaarden en privacystatement"
              text={<>Op alle diensten van Eerstmediation.nl zijn <Link to="/algemene-voorwaarden" className="text-link cursor-pointer">algemene voorwaarden</Link> van toepassing. In het <Link to='/privacystatement' className='text-link cursor-pointer'>privacystatement</Link> kunt u lezen hoe Eerstmediation.nl omgaat met de verwerking van persoonsgegevens en de privacy van cliënten.</>}
            />

            <Accordion
              title="MfN-regels"
              text={<>Eerstmediation.nl is gebonden aan het <Link to="https://mfnregister.nl/mediators/regelgeving-en-documenten/" className="text-link cursor-pointer">MfN Reglement</Link> en de <Link to="https://mfnregister.nl/mediators/regelgeving-en-documenten/" className="text-link cursor-pointer">MfN Gedragsregels</Link>. Het Reglement geeft regels over het mediationproces. Er is ook een toelichting op het reglement. De gedragsregels bevatten regels over de manier waarop de mediator haar werk moet doen. Ook geldt er een klachtenregeling.</>}
            />

            <Accordion
              title="Kantoorklachtenregeling"
              text={<>Wanneer u klachten heeft over de dienstverlening kunt u gebruik maken van <Link to="/kantoorklachtenregeling" className="text-link cursor-pointer">de
              kantoorklachtenregeling</Link>.</>}
            />
          </div>
        </div>
      </section>

      <section className="py-75px px-5 bg-secondary text-center">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary mb-30px">
            Lidmaatschappen
          </h2>
          <ClientLogos />
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-900px mx-auto grid gap-y-3 grid-cols-1 md:grid-cols-3">
          <div className="text-center p-10px">
            <h2 className="text-27px text-primary mb-15px leading-1.2">
              KVK Nummer
            </h2>
            <p className="text-lg text-primary leading-1.625">64700976</p>
          </div>
          <div className="text-center p-10px">
            <h2 className="text-27px text-primary mb-15px leading-1.2">
              BTW-ID
            </h2>
            <p className="text-lg text-primary leading-1.625">NL001896114B63</p>
          </div>
          <div className="text-center p-10px">
            <h2 className="text-27px text-primary mb-15px leading-1.2">
              Bankrekening
            </h2>
            <p className="text-lg text-primary leading-1.625">
              NL23 RABO 0307 8099 35
              <br />
              t.n.v. Eerstmediation.nl te Cuijk
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Kantoor
